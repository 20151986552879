<template>
    <div class="codeID">
        <van-nav-bar
          title="证件照"
          left-arrow fixed
          @click-left="goBack"
          @click-right="isDropdownMenu= !isDropdownMenu"
        >
        <template #right>
                <van-icon size="22" name="apps-o"/>
            </template>
        </van-nav-bar>
        <div class="downMenuModal" v-if="isDropdownMenu" @click="isDropdownMenu = false">
            <div v-if="isDropdownMenu" class="dropdownMenu">
                <div class="menuItem" @click="$router.push('/')">
                    <span>首页</span>
                </div>
                <div class="menuItem" @click="$router.push('/xuanke')">
                    <span>选课中心</span>
                </div>
                <div class="menuItem" @click="$router.push('/tiku')">
                    <span>题库</span>
                </div>
                <div class="menuItem" @click="$router.push('/homeLiveList')">
                    <span>直播</span>
                </div>
                <div class="menuItem" @click="$router.push('/learning')">
                    <span>学习中心</span>
                </div>
                <div class="menuItem" @click="downApp">
                    <span>下载APP</span>
                </div>
            </div>
        </div>
        <div class="content-box">
            <img class="logo-box" src="../../assets/images/logo_alpha.png" />
            <div class="photo-box" style="margin-top:60px">
                <div class="photo-content" @click="setRoute(1)">
                    <div class="photo-left">
                        <img  src="../../assets/images/camera.png" />
                        
                        <div class="titleDetail-box">
                            <span class="title-header">拍摄证件照</span>
                            <span>制作证拍摄或选择照片</span>
                        </div>
                    </div>
                    <div class="photo-right">
                        <img  src="../../assets/all_Icon/drawable-xxhdpi/all_error_iv.png" />
                        
                    </div>

                </div>
            </div>
            <div class="photo-box">
                <div class="photo-content"  @click="setRoute(2)">
                    <div class="photo-left">
                        <img  src="../../assets/images/record.png" />
                        
                        <div class="titleDetail-box-new">
                            <span class="title-header">我的证件照</span>
                        </div>
                    </div>
                    <div class="photo-right">
                        <img  src="../../assets/all_Icon/drawable-xxhdpi/all_error_iv.png" />
                        
                    </div>

                </div>
            </div>
        </div>
            


    </div>
</template>

<script>
export default {
        name: "codeID",
        data() {
            return {
                isDropdownMenu:false,
               
                
            }
        },
        mounted() {
            
        },

        methods: {
            downApp(){
                window.location.href = 'https://www.saen.com/app/';
            },
            goBack() {
              this.$router.go(-1);
            },
            setRoute(val){
                if(val == 1){
                    this.$router.push({
                        path:"/codePhotoList",
                        query:{
                            
                        }
                    })
                    // const { href } = this.$router.resolve({
                    //     path: "/codePhotoList",
                    //     query: {
                    //         id: "1111"
                    //     }
                    // });
                    // window.open(href, "_blank");

                }else{
                    this.$router.push({
                        path:"/myPhoto",
                        query:{
                            
                        }
                    })

                }
            }
            
            
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.codeID{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
   
}
.downMenuModal{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;

        .dropdownMenu{
            position: fixed;
            right: 10px;
            top:44px;
            background-color: #fff;
            z-index: 999;
            width: 100px;
            min-height: 200px;
            box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);

            .menuItem{
                height: 40px;
                line-height: 40px;
                font-size: 13px;
                padding-left: 20px;
            }
            .menuItem5{
                color: #ef8f27;
            }
        }
    }
.content-box{
    width: 100%;
    height: calc(100vh - 46px);
    margin-top: 46px;
    // display: flex;
    
    // justify-content: center;
    background: linear-gradient(#C9D8FA,#FCFDFE );
    .logo-box{
        width: 50%;
        height: 60px;
        margin-top: 50px;
        margin-left: 25%;
    }
    .photo-box{
        width: calc(100% - 60px);
        margin-left: 30px;
        height: 70px;
        display: flex;
        align-items: center;
        margin-top: 20px;
        border-radius: 5px;
        background-color: white;
        .photo-content{
            width: calc(100% - 30px);
            margin-left: 15px;
            height: 50px;
            //margin-top: 10px;
            display: flex;
            justify-content: space-between;
            .photo-left{
                display: flex;
                flex-direction: row;
                img{
                    width: 50px;
                    height: 50px;
                }
                .titleDetail-box{
                    margin-left: 15px;
                    .title-header{
                        font-size: 16px;
                        font-weight: bold;
                        
                    }
                    span{
                        display: block;
                        height: 25px;
                        line-height: 25px;
                    }
                    // display: flex;
                    // flex-direction: column;
                }
                .titleDetail-box-new{
                    margin-left: 15px;
                    .title-header{
                        font-size: 16px;
                        font-weight: bold;
                        
                    }
                    span{
                        display: block;
                        height: 50px;
                        line-height: 50px;
                    }
                    // display: flex;
                    // flex-direction: column;
                }
            }
            .photo-right{
                display: flex;
                flex-direction: row;
                align-items: center;
                img{
                    width: 7px;
                    height: 15px;
                }
            }
        }
    }
}
</style>
